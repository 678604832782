import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { AspectRatio, Box, Flex, Grid, GridItem, SimpleGrid, Skeleton, SkeletonText } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from "swiper"
import "swiper/css"

import { useMedia } from "@app/hooks/useMedia"
import { useShopify } from "@app/hooks/useShopify"
import { FormButton } from "@app/components/Form/FormButton"
import { Icon } from "@app/components/Icon"
import { ProductCard } from "@app/components/Product/Card/ProductCard"

import type { ProductProps } from "@root/types/global"
import { useGlobalContext } from "@app/providers/global"

type Props = {
  handleTrackingClick: () => void
  hasMultiple: boolean
  id: string
  item: GatsbyTypes.SanityObjectSectionFeaturedProducts
  isHomepage?: boolean
}

SwiperCore.use([Navigation])

export const FeaturedProductsItem: React.FC<Props> = memo(({ handleTrackingClick, hasMultiple, id, isHomepage, item }) => {
  const { additionalPrevious, additionalNext } = useGlobalContext()
  const [items, setItems] = useState<Array<ProductProps>>([])
  const { isMedium } = useMedia()
  const { getCollection, getHandle, getProducts } = useShopify()

  const allItems = useMemo(() => item?.products?.filter(item => getHandle(item)) || [], [getHandle, item?.products])

  const fetchItems = useCallback(async () => {
    const items = getHandle(item?.collection)
      ? await getCollection({ firstProducts: 8, firstImages: 2, firstVariants: 1, handle: getHandle(item?.collection) })
      : allItems.length > 0
      ? await getProducts({ firstImages: 2, firstVariants: 1, handles: allItems.map(item => getHandle(item)) })
      : []

    const normalisedProducts = items?.products || items

    if (normalisedProducts) setItems(normalisedProducts)
  }, [allItems, getCollection, getHandle, getProducts, item])

  // intentionally only run once at first render
  useEffect(() => {
    if (!items.length && (allItems.length || item?.collection)) fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {items?.length > 0 ? (
        <>
          {hasMultiple && items.length > 4 && isMedium ? (
            <Flex flexDirection="column" alignItems="stretch" justifyContent="flex-start">
              <Box display={{ base: "none", md: items.length > 4 ? "block" : "none" }} alignSelf="flex-end" mt={-16} w={100} mb={5}>
                <Flex alignItems="center" justifyContent="flex-end">
                  <FormButton id={`${id}-left`} p={3} title={additionalPrevious} variant="ghost">
                    <Icon name="chevron-left" width={5} />
                  </FormButton>
                  <FormButton id={`${id}-right`} p={3} title={additionalNext} variant="ghost">
                    <Icon name="chevron-right" width={5} />
                  </FormButton>
                </Flex>
              </Box>
              <Box
                w="full"
                sx={{
                  ".swiper": { w: "full" },
                  ".swiper-slide": { overflow: "hidden" },
                  ".swiper-button-disabled": { opacity: 0, visibility: "hidden" },
                }}
              >
                <Swiper cssMode navigation={{ prevEl: `#${id}-left`, nextEl: `#${id}-right` }} slidesPerView={4} spaceBetween={20}>
                  {items.map((item, index) => (
                    <SwiperSlide key={item.id}>
                      <ProductCard item={item} pagePosition={index + 1} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            </Flex>
          ) : hasMultiple ? (
            <Grid
              gap={{ base: 0, md: 5 }}
              gridAutoFlow="column"
              mx={[-6.25, -7.5, 0]}
              overflowX={{ base: "unset", md: "unset" }}
              overflowY={{ base: "hidden", md: "unset" }}
              css={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
              pl={{ base: 6.25, md: 0 }}
              templateColumns={[`repeat(${items.length}, 70vw)`, `repeat(${items.length}, 35vw)`, `repeat(4, minmax(0, 1fr))`]}
            >
              {items.map((item, index) => (
                <ProductCard
                  key={item.id}
                  handleTrackingClick={handleTrackingClick}
                  item={item}
                  pagePosition={index + 1}
                  pr={{ base: 5, md: 0 }}
                  isHomepage={isHomepage}
                />
              ))}
            </Grid>
          ) : (
            <SimpleGrid columns={[2, 4]} gap={[4, 5]}>
              {items.map((item, index) => (
                <ProductCard
                  key={item.id}
                  handleTrackingClick={handleTrackingClick}
                  item={item}
                  pagePosition={index + 1}
                  pb={12.5}
                  isHomepage={isHomepage}
                />
              ))}
            </SimpleGrid>
          )}
        </>
      ) : (
        <>
          {hasMultiple ? (
            <Grid
              gap={{ base: 0, md: 5 }}
              gridAutoFlow="column"
              mx={[-6.25, -7.5, 0]}
              pl={{ base: 6.25, md: 0 }}
              overflowX={{ base: "auto", md: "unset" }}
              overflowY={{ base: "hidden", md: "unset" }}
              templateColumns={[
                `repeat(${isMedium ? items?.length : 2}, 70vw)`,
                `repeat(${isMedium ? items?.length : 2}, 35vw)`,
                `repeat(4, minmax(0, 1fr))`,
              ]}
            >
              {Array.from(Array(4).keys()).map((_, index) => (
                <GridItem key={index} colSpan={1} pr={{ base: 5, md: 0 }}>
                  <AspectRatio ratio={320 / 450} mb={6}>
                    <Skeleton />
                  </AspectRatio>
                  <SkeletonText w="full" mb={4} />
                </GridItem>
              ))}
            </Grid>
          ) : (
            <SimpleGrid columns={[2, 4]} gap={[4, 5]}>
              {Array.from(Array(8).keys()).map((_, index) => (
                <GridItem key={index} colSpan={1} pb={12.5}>
                  <AspectRatio ratio={320 / 450} mb={6}>
                    <Skeleton />
                  </AspectRatio>
                  <SkeletonText w="full" mb={4} />
                </GridItem>
              ))}
            </SimpleGrid>
          )}
        </>
      )}
    </>
  )
})
