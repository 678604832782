import React, { memo, useCallback, useMemo, useState } from "react"
import { Container, Flex, HStack, Heading, Select, Text } from "@chakra-ui/react"

import { withSection } from "@app/hoc/Section"
import { Icon } from "@app/components/Icon"
import { FormButton } from "@app/components/Form/FormButton"
import { FeaturedProductsItem } from "./FeaturedProductsItem"

import type { Section } from "@root/types/global"

type Props = Section & GatsbyTypes.SanitySectionFeaturedProducts

const FeaturedProducts: React.FC<Props> = withSection(
  memo(({ handleTrackingClick, id, products, tag, title, isHomepage }) => {
    const [active, setActive] = useState<number>(0)

    const items = useMemo(
      () =>
        products?.map(item => ({
          ...item,
          key: item._key,
        })),
      [products]
    )

    const activeProduct = useMemo(() => items?.[active], [active, items])

    const hasMultiple = useMemo(() => items?.length > 1, [items?.length])

    const handleChange = useCallback((index: number) => setActive(index), [setActive])

    return items?.length > 0 ? (
      <Container as="section" {...(hasMultiple ? { py: { base: 5, md: 6, lg: 8 } } : { py: 8 })}>
        {title && (
          <Heading
            as={tag}
            textTransform="capitalize"
            color={hasMultiple ? "grey.coal" : undefined}
            mb={hasMultiple ? 3 : [18, 25]}
            maxW={["unset", 700]}
            size={hasMultiple ? "h3" : "h4"}
            textAlign="center"
            letterSpacing={0}
            fontWeight={500}
            // Always use "2xl" size for mobile homepage
            // https://app.productive.io/1476-dotdev/tasks/2681468
            {...(!hasMultiple
              ? { fontSize: "xl", lineHeight: 1.75, mx: [-3, "auto"] }
              : { fontSize: isHomepage ? "3xl" : ["5xl", "2xl"], mx: "auto" })}
          >
            {title}
          </Heading>
        )}
        {hasMultiple && (
          <>
            <Flex display={{ base: "flex", md: "none" }} flexDirection="column" alignItems="center" justifyContent="center">
              <Flex display={{ base: "flex", md: "none" }} pos="relative" alignItems="center" justifyContent="center" mb={8.75}>
                <Select
                  pos="absolute"
                  inset={0}
                  icon={<></>}
                  onChange={({ target: { value } }) => handleChange(parseInt(value))}
                  opacity={0}
                  value={active}
                  variant="ghost"
                  textTransform="initial"
                  color="grey.coal"
                  fontSize="sm"
                >
                  {items?.map(({ _key, title }: { _key: string; title: string }, index: number) => (
                    <option key={_key} value={index}>
                      {title}
                    </option>
                  ))}
                </Select>
                <Text borderBottom="1px solid" borderBottomColor="grey.coal" mr={2} size="md" variant="medium" color="grey.coal">
                  {activeProduct?.title}
                </Text>
                <Icon name="chevron-down" width={4} color="grey.coal" />
              </Flex>
            </Flex>
            <HStack as="nav" alignItems="flex-start" justifyContent="center" display={{ base: "none", md: "flex" }} spacing={7.5} mb={8.75}>
              {items.map(({ _key, title }, index) => (
                <FormButton
                  key={_key}
                  color="grey.coal"
                  onClick={() => handleChange(index)}
                  size="underline"
                  title={title}
                  arial-label={title}
                  variant="underlineTabs"
                  isActive={active === index}
                  textTransform="initial"
                  fontSize="md"
                  letterSpacing="ample"
                  lineHeight={1}
                >
                  {title}
                </FormButton>
              ))}
            </HStack>
          </>
        )}
        {items.map((item, index) =>
          index === active ? (
            <FeaturedProductsItem
              key={item?._key}
              handleTrackingClick={handleTrackingClick}
              hasMultiple={hasMultiple}
              id={id}
              item={item}
              isHomepage={isHomepage}
            />
          ) : null
        )}
      </Container>
    ) : null
  })
)

export default FeaturedProducts
